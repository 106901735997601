<template>
  <div class="search">
    <div v-if="!selected && !loadingSingle">
      <b-form-group :invalid-feedback="validationInvalidFeedback(errorFeed, errorKey)">
        <b-form-input
          :id="`search-field-${this._uid}`"
          v-model="term"
          :disabled="disabled"
          :placeholder="`Search for ${this.moduleDisplay}...`"
          :state="validationState(errorFeed, errorKey)"
          class="mb-0"
          tabindex="0"
          @focus="searchFieldFocus"
          @update="search"></b-form-input>
      </b-form-group>
      <b-popover
        id="search-results"
        :target="`search-field-${this._uid}`"
        class="p-0 w-100"
        placement="bottom"
        triggers="focus">
        <div v-if="!term" class="text-center">
          <p v-if="!loading && !results.length" class="my-3 text-muted">
            Please start typing to search for {{ moduleDisplay }}.
          </p>
          <b-spinner v-if="loading || !results" class="mx-4 my-2"></b-spinner>
        </div>
        <b-list-group v-if="term && !loading && canHaveNewItem">
          <b-list-group-item href="#" @click.prevent="select({ term })">
            <slot :result="term" name="popover-list-new"/>
          </b-list-group-item>
        </b-list-group>
        <b-list-group v-if="!loading && results">
          <b-list-group-item
            v-for="result in results"
            :key="result.id"
            href="#"
            @click.prevent="select({ result })">
            <slot :result="result" name="popover-list"/>
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </div>
    <b-card v-if="selected || loadingSingle">
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-row p-2">
            <div class="d-flex flex-fill">
              <b-spinner v-if="loadingSingle" class="m-2"/>
              <b-row class="ml-1 mr-1">
                <b-col>
                  <slot
                    v-if="!loadingSingle"
                    :selected="selected"
                    name="selected"/>
                </b-col>
                <b-col v-if="$slots['selected-inline-form-area']">
                  <slot name="selected-inline-form-area"/>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-if="!disabled"
              variant="primary"
              @click.prevent="deselect">
              <fa-icon icon="pen"/>
            </b-button>
            <b-button
              v-if="!disabled && showDelete"
              class="ml-2"
              variant="danger"
              @click.prevent="$emit('delete', null)">
              <fa-icon icon="trash"/>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <slot name="selected-form-area"/>
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="selected">
      <b-col cols="12">
        <small class="text-danger">{{ validationInvalidFeedback(errorFeed, errorKey) }}</small>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import validation from "../mixins/validation";

export default {
  mixins: [validation],
  async mounted() {
    if (this.value) {
      if (typeof this.value == "object" && this.value.id != null) {
        this.loadingSingle = true;
        await this.fetchSingle(this.value.id).then(data => {
          this.loadingSingle = false;
          this.selected = data.data;
        });
      } else {
        if (this.value > 0 && this.value != null) {
          this.loadingSingle = true;
          await this.fetchSingle(this.value).then(data => {
            this.loadingSingle = false;
            this.selected = data.data;
          });
        }
      }
    }
  },
  props: {
    disabled: Boolean,
    fetchParams: {
      type: Object,
      default: () => ({})
    },
    module: String,
    showDelete: {
      type: Boolean,
      default: false
    },
    waitForInput: {
      type: Boolean,
      default: true
    },
    errorFeed: Object,
    errorKey: String,
    inputState: {
      type: Boolean,
      default: null
    },
    canHaveNewItem: {
      type: Boolean,
      default: false
    },
    value: Object
  },
  data() {
    return {
      loadingSingle: false,
      selected: null,
      term: ""
    };
  },
  computed: {
    ...mapState({
      results(state) {
        return state[this.module].data;
      },
      loading(state) {
        return state[this.module].loading;
      }
    }),
    moduleDisplay() {
      return this.module.replace(/-/g, " ");
    }
  },
  methods: {
    ...mapActions({
      fetch(dispatch, payload) {
        return dispatch(`${this.module}/fetchMany`, payload);
      },
      fetchSingle(dispatch, payload) {
        return dispatch(`${this.module}/fetch`, payload);
      },
      empty(dispatch, payload) {
        return dispatch(`${this.module}/empty`);
      }
    }),
    searchFieldFocus() {
      if (!this.waitForInput) this.search();
    },
    select(obj) {
      if (obj.hasOwnProperty("term")) {
        switch (this.module) {
          case "products":
            this.selected = {
              id: null,
              name: this.term,
              category: null,
              cost_price: null,
              created_at: null,
              deleted_at: null,
              other: null,
              quantity: null,
              sale_price: null,
              supplier: {
                id: null,
                name: null,
                address_line_1: null,
                address_line_2: null,
                county: null,
                postcode: null,
                contact_number: null,
                email: null
              },
              supplier_id: null,
              tax: null,
              updated_at: null,
              type: "new"
            };
            break;
        }
      }
      if (obj.hasOwnProperty("result")) {
        this.selected = this.results.find(x => x.id === obj.result.id);
      }
      this.$emit("input", this.selected);
      this.empty();
    },
    deselect() {
      this.selected = null;
      this.$emit("input", {id: null});
      this.empty();
    },
    search() {
      return this.fetch({
        query: this.term,
        page: 1,
        extraParams: this.fetchParams
      });
    }
  },
  watch: {
    value(v) {
      if (!v || (typeof v === "object" && Object.keys(v).length === 0)) {
        this.selected = null;
        this.term = "";
        return;
      }
      this.fetchSingle(v.id).then(({data}) => {
        this.loadingSingle = false;
        this.selected = data;
      });
    }
  }
};
</script>
<style lang="scss">
#search-results {
  max-width: 50%;
  width: 50%;
  max-height: 50vh;
  overflow-y: scroll;

  .popover-body {
    padding: 0 !important;
    border: none;
  }

  .list-group-item {
    border-left: none;
    border-right: none;
  }

  .list-group-item:first-child {
    border-top: none;
  }

  .list-group-item:last-child {
    border-bottom: none;
  }
}
</style>
